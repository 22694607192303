<script setup lang="ts">
// icons
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

// components
import AutoHeightTransition from './AutoHeightTransition.vue';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiAccordionProps {
  isLast?: boolean;
  layout?: 'default' | 'primary';
  mq?: 'default' | 'sm' | 'md' | 'lg';
  removeCollapsedMarkup?: boolean;
  scrollOffset?: number;
  scrollToItem?: boolean;
  show?: boolean;
  smallIcon?: boolean;
}
const props = withDefaults(defineProps<CiAccordionProps>(), {
  isLast: false,
  layout: 'default',
  mq: 'default',
  removeCollapsedMarkup: false,
  scrollOffset: 0,
  scrollToItem: true,
  show: false,
  smallIcon: false,
});

const emit = defineEmits<{
  'accordion-toggle': [value: boolean];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const viewport = useViewport();

/********************
 * REFS & VARS      *
 ********************/
const root = ref<HTMLElement | null>(null);
const open = computed({
  // getter
  get() {
    if (props.mq === 'default') {
      return props.show;
    } else {
      return viewport.isLessThan(props.mq) ? props.show : true;
    }
  },
  // setter
  set(newValue) {
    return newValue;
  },
});

const showOpenStyles = computed({
  // getter
  get() {
    if (props.mq === 'default') {
      return props.layout !== 'default';
    } else {
      return viewport.isLessThan(props.mq) ? props.show : false;
    }
  },
  // setter
  set(newValue) {
    return newValue;
  },
});

const classMapping = {
  default: {
    header: {
      default: 'cursor-pointer',
      open: 'cursor-pointer',
    },
    headline: '[&>*]:max-md:text-base [&>*]:max-md:font-medium [&>*]:max-md:leading-6',
    body: {
      default: '',
      open: '',
    },
  },
  sm: {
    header: {
      default: 'cursor-pointer sm:!cursor-auto sm:relative sm:border-b-0',
      open: 'cursor-pointer',
    },
    headline: '[&>*]:max-sm:text-base [&>*]:max-sm:font-medium [&>*]:max-sm:leading-6',
    body: {
      default: 'sm:!block sm:h-auto sm:overflow-visible',
      open: 'border-b-0',
    },
  },
  md: {
    header: {
      default: 'cursor-pointer md:!cursor-auto md:relative md:border-b-0',
      open: 'cursor-pointer',
    },
    headline: '[&>*]:max-md:text-base [&>*]:max-md:font-medium [&>*]:max-md:leading-6',
    body: {
      default: 'md:!block sm:h-auto md:overflow-visible',
      open: 'border-b-0',
    },
  },
  lg: {
    header: {
      default: 'cursor-pointer lg:!cursor-auto lg:relative lg:border-b-0',
      open: 'cursor-pointer',
    },
    headline: '[&>*]:max-lg:text-base [&>*]:max-lg:font-medium [&>*]:max-lg:leading-6',
    body: {
      default: 'lg:!block lg:h-auto lg:overflow-visible',
      open: 'border-b-0',
    },
  },
};

const styleMapping = {
  default: {
    header: {
      default: 'border-t border-[#adb5bd]',
      open: 'border-t border-[#adb5bd]',
    },
    icon: {
      default: 'fill-primary',
      open: 'fill-primary',
    },
    body: {
      default: '',
      open: props.isLast ? 'border-b border-[#adb5bd]' : '',
    },
  },
  primary: {
    header: {
      default: 'border-b border-[#adb5bd]',
      open: 'bg-primary text-white',
    },
    icon: {
      default: 'fill-primary',
      open: 'fill-white',
    },
    body: {
      default: '',
      open: props.isLast ? '' : 'border-b border-[#adb5bd]',
    },
  },
};

const bodyLayoutClass = computed(() => {
  return styleMapping[props.layout].body.default;
});

const iconLayoutClass = computed(() => {
  return styleMapping[props.layout].icon.default;
});

const iconOpenLayoutClass = computed(() => {
  return styleMapping[props.layout].icon.open;
});

const bodyOpenLayoutClass = computed(() => {
  return styleMapping[props.layout].body.open;
});

const bodyClass = computed(() => {
  return classMapping[props.mq].body.default;
});

const headlineClass = computed(() => {
  return classMapping[props.mq].headline;
});

const bodyOpenClass = computed(() => {
  return classMapping[props.mq].body.open;
});

const headerLayoutClass = computed(() => {
  return styleMapping[props.layout].header.default;
});

const headerOpenLayoutClass = computed(() => {
  return styleMapping[props.layout].header.open;
});

const headerClass = computed(() => {
  return classMapping[props.mq].header.default;
});

const headerOpenClass = computed(() => {
  return classMapping[props.mq].header.open;
});

/********************
 * WATCHER          *
 ********************/
watch(() => props.show, onShowChange);

/********************
 * FUNCTIONS        *
 ********************/
function toggle(): void {
  if (props.mq === 'default' || viewport.isLessThan(props.mq)) {
    emit('accordion-toggle', !props.show);
  }
}

function scrollToElem(): void {
  const topPos = (root.value as HTMLElement)?.getBoundingClientRect().top + window.scrollY;
  window.scroll({ top: topPos - props.scrollOffset, behavior: 'smooth' });
}

function onShowChange(isOpen: boolean): void {
  if (isOpen && props.scrollToItem) {
    nextTick(() => {
      setTimeout(() => {
        scrollToElem();
      }, 300);
    });
  }
}
</script>

<template>
  <div
    ref="root"
    :class="{ open: props.show }"
    class="accordion w-full"
  >
    <NuxtLazyHydrate when-visible>
      <div
        class="accordion__header"
        :class="[
          props.show && showOpenStyles ? headerOpenLayoutClass : headerLayoutClass,
          props.show && showOpenStyles ? headerOpenClass : headerClass,
        ]"
        @click="toggle"
      >
        <div class="container">
          <div class="row">
            <div
              :class="[
                headlineClass,
                props.smallIcon ? 'col-10 col-sm-11' : 'col-10',
                mq ? `col-${props.mq}-12 ${props.mq}:!p-0` : null,
              ]"
            >
              <slot name="header" />
            </div>
            <div
              class="flex justify-end"
              :class="[props.smallIcon ? 'col-2 col-sm-1' : 'col-2', props.mq ? `${props.mq}:hidden` : null]"
            >
              <CiAwesomeIcon
                :class="[props.show ? iconOpenLayoutClass : iconLayoutClass, { 'rotate-180': props.show }]"
                :icon="faChevronDown"
                class="accordion__icon rotate-0 self-center fill-primary transition-transform duration-[250ms] ease-in"
                ratio="0.75"
              />
            </div>
          </div>
        </div>
      </div>

      <auto-height-transition>
        <template v-if="!removeCollapsedMarkup">
          <div
            v-show="open"
            class="accordion__body"
            :class="[
              props.show && showOpenStyles ? bodyOpenLayoutClass : bodyLayoutClass,
              props.show && showOpenStyles ? bodyOpenClass : bodyClass,
              { 'visible relative h-auto': open },
            ]"
          >
            <slot />
          </div>
        </template>

        <template v-else>
          <div
            v-if="open"
            class="accordion__body"
            :class="[
              props.show && showOpenStyles ? bodyOpenLayoutClass : bodyLayoutClass,
              props.show && showOpenStyles ? bodyOpenClass : bodyClass,
              { 'visible relative h-auto': open },
            ]"
          >
            <slot />
          </div>
        </template>
      </auto-height-transition>
    </NuxtLazyHydrate>
  </div>
</template>

<style></style>
